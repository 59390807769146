import Roles from '@/api/Roles'

const state = {
  schoolRolesOption: null
}

const mutations = {
  SET_SCHOOL_ROLES_OPTION: (state, schoolRolesOption) => {
    state.schoolRolesOption = schoolRolesOption
  }
}

const actions = {
  async getSchoolRolesOption({ commit }) {
    try {
      const { data } = await Roles.list()
      commit('SET_SCHOOL_ROLES_OPTION', data || [])
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  schoolRolesOption: (state) => state.schoolRolesOption
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
