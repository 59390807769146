const state = {
  typeOption: [
    // 插卡狀態
    {
      value: 0,
      name: '無資訊'
    },
    {
      value: 1,
      name: '計費卡'
    },
    {
      value: 2,
      name: '免費卡'
    },
    {
      value: 3,
      name: '強制卡'
    },
    {
      value: 4,
      name: '禁用卡'
    }
  ],
  statusOption: [
    // 插卡狀態
    {
      value: 0,
      name: '拔出'
    },
    {
      value: 1,
      name: '插入'
    }
  ]
}

const mutations = {}

const actions = {}

const getters = {
  typeOption: (state) => state.typeOption,
  statusOption: (state) => state.statusOption
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
