import settings from '@/settings'

/**
 * 學校 能源看板
 */
class SchoolDashboard {
  /**
   * 查詢學校太陽能發電資料
   */
  getPV(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/dashboard/pv/${schoolId}`)
  }

  /**
   * 查詢學校太陽能即時資料
   */
  getSolar(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/dashboard/solar/${schoolId}`)
  }

  /**
   * 查詢學校用電資訊
   */
  getEnergy(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/dashboard/schoolpv/${schoolId}`
    )
  }

  /**
   * 查詢學校冷氣用電資訊
   */
  getACEnergy(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/dashboard/acpv/${schoolId}`)
  }

  /**
   * 查詢學校減碳量
   */
  getCO2(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/dashboard/schoolco2/${schoolId}`
    )
  }

  /**
   * 查詢學校即時用電資訊
   */
  getRealtimePV(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/dashboard/schoolrealtimepv/${schoolId}`
    )
  }

  /**
   * 查詢系統時間
   */
  getServerTime() {
    return axios.get(settings.api.fullPath + `/ems/dashboard/time`)
  }
}

var schoolDashboard = new SchoolDashboard()
export default schoolDashboard
