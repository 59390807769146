// Nagiosxi Controller
const state = {
  typeOptions: [
    {
      value: 1,
      text: '需量管理',
      actionOptions: [
        {
          value: 1,
          text: '需量超約開始'
        },
        {
          value: 2,
          text: '超約事件結束'
        },
        {
          value: 3,
          text: '需量警戒開始'
        },
        {
          value: 4,
          text: '需量警戒結束'
        },
        {
          value: 5,
          text: '需量卸載執行'
        },
        {
          value: 6,
          text: '需量卸載結束'
        }
      ]
    },
    {
      value: 2,
      text: 'EMS控制',
      actionOptions: [
        {
          value: 1,
          text: '開機'
        },
        {
          value: 2,
          text: '關機'
        },
        {
          value: 3,
          text: '送風模式'
        },
        {
          value: 4,
          text: '冷氣模式'
        },
        {
          value: 5,
          text: '溫度切換'
        },
        {
          value: 6,
          text: '自動模式'
        },
        {
          value: 7,
          text: '暖氣模式'
        }
      ]
    },
    {
      value: 3,
      text: '冷氣狀態改變',
      actionOptions: [
        {
          value: 1,
          text: '開機'
        },
        {
          value: 2,
          text: '關機'
        },
        {
          value: 3,
          text: '送風模式'
        },
        {
          value: 4,
          text: '冷氣模式'
        },
        {
          value: 5,
          text: '溫度切換'
        },
        {
          value: 6,
          text: '自動模式'
        },
        {
          value: 7,
          text: '暖氣模式'
        }
      ]
    },
    {
      value: 4,
      text: 'ADR管理',
      actionOptions: [
        {
          value: 1,
          text: 'ADR事件預告'
        },
        {
          value: 2,
          text: 'ADR事件開始'
        },
        {
          value: 3,
          text: 'ADR事件結束'
        },
        {
          value: 4,
          text: '執行溫度調整型'
        },
        {
          value: 5,
          text: '執行空調循環型'
        },
        {
          value: 6,
          text: '執行卸載量約定型'
        },
        {
          value: 7,
          text: '結束溫度調整型'
        },
        {
          value: 8,
          text: '結束空調循環型'
        },
        {
          value: 9,
          text: '結束卸載量約定型'
        },
        {
          value: 1,
          text: '	ADR事件中斷'
        }
      ]
    },
    {
      value: 5,
      text: '卡機',
      actionOptions: [
        {
          value: 1,
          text: '餘額不足'
        }
      ]
    },
    {
      value: 6,
      text: '設備異常',
      actionOptions: [
        {
          value: 1,
          text: '設備斷線'
        },
        {
          value: 1,
          text: '資料停止'
        }
      ]
    }
  ],
  warnings: []
}

const mutations = {}

const actions = {}

const getters = {
  typeOptions: (state) => state.typeOptions,
  warnings: (state) => state.warnings
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
