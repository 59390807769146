import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './vendor'

/**
 * for msw
 */
// const serverConfigurationObject = {
//   onUnhandledRequest(request) {
//     // avoid assets, fonts, images, or vite internal or auth middleware to be processed by mock server
//     const regexTest = /^.*\/api\/(?!.*\.ts).*$/.test(request.url.href)
//     console.log(regexTest ? request.url.href : '')
//     if (regexTest) {
//     } else {
//       return
//     }
//   }
// }

// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start(
//     serverConfigurationObject
//     //   {
//     //   onUnhandledRequest: 'bypass'
//     // }
//   )
// }

Vue.config.productionTip = false
// 權限
import './permission' // permission control
import permission from '@/directives/permission' // 權限判斷
Vue.use(permission)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
