const state = {
  typeOption: [
    // 排程類型
    {
      value: 'day',
      text: '例外日期'
    },
    {
      value: 'week',
      text: '每週循環'
    }
  ]
}

const mutations = {}

const actions = {}

const getters = {
  typeOption: (state) => state.typeOption
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
