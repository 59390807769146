import settings from '@/settings'
export default {
  inserted(el, binding) {
    const { value } = binding
    if (value && value instanceof Array && value.length > 0) {
      const citys = value
      const hasPermission = citys.includes(settings.edu.city)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(
        `need roles! Like v-permission-with-city="['tainan', 'keelung']"`
      )
    }
  }
}
