// Nagiosxi Controller
import Group from '@/api/ems/Group'

const state = {
  groups: null,
  typeOptions: [
    {
      id: 'CLASSMETER',
      name: '資料查詢及分析'
    },
    {
      id: 'AIRCONDITIONER',
      name: '控制冷氣'
    },
    {
      id: 'IR_AIRCONDITIONER',
      name: '控制IR冷氣'
    },
    {
      id: 'CARDREADER',
      name: '控制既設冷氣'
    }
  ]
}

const mutations = {
  SET_GROUPS: (state, groups) => {
    state.groups = groups
  }
}

const actions = {
  async getGroups({ commit }, schoolId) {
    try {
      const { data } = await Group.getGroups(schoolId)
      this.groups = data
      commit('SET_GROUPS', data)
    } catch (error) {
      console.error(error)
    }
  }
}
const getters = {
  groups: (state) => state.groups,
  typeOptions: (state) => state.typeOptions
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
