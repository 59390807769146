const state = {
  type: [
    // 通知類型
    {
      value: '1',
      name: '需量管理',
      cause: 'type-1'
    },
    {
      value: '2',
      name: 'EMS 控制',
      cause: 'type-2'
    },
    {
      value: '3',
      name: '冷氣狀態變更',
      cause: 'type-2'
    },
    {
      value: '4',
      name: 'Open ADR',
      cause: 'type-3'
    },
    {
      value: '5',
      name: '餘額提示',
      cause: 'type-4'
    },
    {
      value: '6',
      name: '設備異常',
      cause: 'type-5'
    }
  ],
  cause: {
    // 通知原因
    // 需量
    'type-1': [
      {
        value: '1',
        name: '超約事件開始'
      },
      {
        value: '2',
        name: '超約事件結束'
      },
      {
        value: '3',
        name: '需量警戒開始'
      },
      {
        value: '4',
        name: '需量警戒結束'
      },
      {
        value: '5',
        name: '需量卸載執行'
      },
      {
        value: '6',
        name: '需量卸載結束'
      }
    ],
    'type-2': [
      {
        value: '1',
        name: '開機'
      },
      {
        value: '2',
        name: '關機'
      },
      {
        value: '3',
        name: '送風模式'
      },
      {
        value: '4',
        name: '冷氣模式'
      },
      {
        value: '5',
        name: '溫度切換'
      },
      {
        value: '6',
        name: '自動模式'
      },
      {
        value: '7',
        name: '暖氣模式'
      },
      {
        value: '8',
        name: '除濕模式'
      }
    ],
    'type-3': [
      {
        value: '1',
        name: 'ADR事件預告'
      },
      {
        value: '2',
        name: 'ADR事件開始'
      },
      {
        value: '3',
        name: 'ADR事件結束'
      },
      {
        value: '4',
        name: '執行溫度調整型' //ADR卸載執行溫度調整型
      },
      {
        value: '5',
        name: '執行空調循環型' //ADR卸載執行空調循環型
      },
      {
        value: '6',
        name: '執行卸載量約定型' //ADR卸載執行卸載量約定型
      },
      {
        value: '7',
        name: '結束溫度調整型' //ADR卸載結束溫度調整型
      },
      {
        value: '8',
        name: '結束空調循環型' //ADR卸載結束空調循環型
      },
      {
        value: '9',
        name: '結束卸載量約定型' //ADR卸載結束卸載量約定型
      },
      {
        value: '10',
        name: 'ADR事件中斷'
      }
    ],
    'type-4': [
      {
        value: '1',
        name: '餘額不足'
      }
    ],
    'type-5': [
      {
        value: '1',
        name: '設備斷線'
      },
      {
        value: '2',
        name: '資料停止'
      },
      {
        value: '3',
        name: '冷氣運轉異常'
      }
    ]
  }
}

const mutations = {}

const actions = {}

const getters = {
  types: (state) => state.type,
  causes: (state) => state.cause
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
