// Nagiosxi Controller
import Building from '@/api/ems/Building'

const state = {
  buildings: null
}

const mutations = {
  SET_BUILDINGS: (state, buildings) => {
    state.buildings = buildings
  }
}

const actions = {
  async getAllBuildings({ commit, rootGetters }, schoolId) {
    commit('SET_BUILDINGS', null)
    const id = schoolId || rootGetters['user/userPermission'].schoolId
    console.log('getAllBuildings', id)
    if (!id) {
      throw new Error('schoolId doesnt exit')
    }
    try {
      const { data } = await Building.getAll(id)
      commit('SET_BUILDINGS', data)
    } catch (error) {
      console.error(error)
    }
  }
}
const getters = {
  buildings: (state, getters) => {
    if (!state.buildings) {
      return null
    }
    return state.buildings.map((building) => {
      building.classesList = getters.classList.filter(
        (classData) => classData.buildingId === building.id
      )
      building.deviceList = getters.deviceList.filter(
        (classData) => classData.buildingId === building.id
      )
      return building
    })
  },
  floorList: (state, getters) => {
    if (!state.buildings) {
      return null
    }
    const floorList = []
    state.buildings.forEach((building) => {
      building.floorList.forEach((floor) => {
        floor.buildingId = building.id
        floor.deviceList = getters.deviceList.filter(
          (classData) => classData.floorId === floor.id
        )
        floor.classList = floor.classroomList
        floorList.push(floor)
      })
    })
    return floorList
  },
  classList: (state, getters) => {
    if (!state.buildings) {
      return null
    }
    const classList = []
    getters.floorList.forEach((floor) => {
      floor.classList.forEach((classData) => {
        classData.floorId = floor.id
        classData.buildingId = floor.buildingId
        classList.push(classData)
      })
    })
    return classList
  },
  deviceList: (state) => {
    if (!state.buildings) {
      return null
    }
    const deviceList = []
    state.buildings.forEach((building) => {
      building.floorList.forEach((floor) => {
        floor.classroomList.forEach((classData) => {
          classData.deviceList.forEach((device) => {
            device.schoolId = building.schoolId
            device.buildingId = building.id
            device.floorId = floor.id
            device.classroomId = classData.id
            deviceList.push(device)
          })
        })
      })
    })
    return deviceList
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
