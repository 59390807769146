import settings from '@/settings'
import axios from 'axios'
/**
 * 設備資訊
 */
class Device {
  /**
   * 查詢設備各狀態數量
   */
  statusList(schoolId, params) {
    return axios.get(
      settings.api.fullPath + `/device/status/count/${schoolId}`,
      {
        params: params
      }
    )
  }

  /**
   * 查詢設備異常紀錄
   */
  disconnectList(schoolId, params) {
    return axios.get(settings.api.fullPath + `/device/disconnect/${schoolId}`, {
      params: params
    })
  }

  /**
   * 查詢該學校所有設備更新時間與狀態
   */
  getSchoolDeviceStatus(schoolId) {
    return axios.get(`${settings.api.fullPath}/device/status/${schoolId}`)
  }

  /** 查詢斷線中設備
   */
  currentDisconnectList(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/device/disconnecting/${schoolId}`
    )
  }

  /**
   * 學校各班級裝置列表
   */
  getClassroomsDevices(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/device/school/${schoolId}/classroom`
    )
  }

  /**
   * 班級裝置列表
   */
  getClassroomDevices(classroomId) {
    return axios.get(`${settings.api.fullPath}/device/classroom/${classroomId}`)
  }
}

var device = new Device()
export default device
