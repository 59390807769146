const getDefaultState = () => {
  return {
    show: false,
    message: '',
    color: 'primary',
    closable: false,
    refresh: false,
    position: 'bottom',
    time: 3000,
    contentClass: 'text-left font-weight-bold'
  }
}

// init state
const state = getDefaultState()

const mutations = {
  SET_SHOW: (state, force) => {
    state.show = force
  },
  SET_MESSAGE: (state, message) => {
    state.message = message
  },
  SET_COLOR: (state, color) => {
    state.color = color
  },
  SET_CLOSABLE: (state, closable) => {
    state.closable = closable
  },
  SET_REFRESH: (state, refresh) => {
    state.refresh = refresh
  },
  SET_POSITION: (state, position) => {
    state.position = position
  },
  SET_TIME: (state, time) => {
    state.time = time
  },
  SET_CONTENT_CLASS: (state, style) => {
    state.contentClass = style
  },
  RESET: (state) => {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  // show snackbar
  show(
    { commit },
    {
      message = 'message',
      color = 'success',
      time = 3000,
      closable = false,
      refresh = false,
      position = 'bottom',
      contentClass
    }
  ) {
    commit('SET_MESSAGE', message)
    commit('SET_COLOR', color)
    commit('SET_CLOSABLE', closable)
    commit('SET_REFRESH', refresh)
    commit('SET_POSITION', position)
    commit('SET_TIME', time)
    commit('SET_SHOW', true)
    if (contentClass) {
      commit('SET_CONTENT_CLASS', contentClass)
    }
    // reset after animation end
    if (time > 0) {
      setTimeout(() => {
        commit('RESET')
      }, time + 300)
    }
    return {}
  },
  close({ commit }) {
    commit('SET_SHOW', false)
    setTimeout(() => {
      commit('RESET')
    }, 300)
  }
}

const getters = {
  snackbar: (state) => state
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
