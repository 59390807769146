const distOptionContainer = () =>
  import('@/views/containers/distOptionContainer')

export default {
  path: 'dist',
  redirect: '/',
  component: distOptionContainer,
  props: true,
  meta: {},
  children: [
    {
      path: ':tagId',
      redirect: ':tagId/overview',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          path: 'overview',
          props: true,
          component: () => import('@/views/dist/overview/index'),
          meta: {
            bgCSS: 'BG-01',
            permission: ['LOGOUT']
          }
        },
        {
          path: 'report',
          props: true,
          // 縣市或區域都使用顯示統計報表
          component: () => import('@/views/city/report/index'),
          meta: {
            name: '統計報表',
            bgCSS: 'BG-02',
            permission: ['LOGOUT']
          }
        }
      ]
    }
  ]
}
