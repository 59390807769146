import Vue from 'vue'

// axios
import axios from './axios'
window.axios = axios

// echarts
// import echarts from './echarts'
// window.echarts = echarts
// ECharts
import ECharts from 'vue-echarts'
Vue.component('v-chart', ECharts)
window.echarts = ECharts

// moment
import moment from 'moment'
require('moment/locale/zh-tw')
moment.locale('zh-tw')
window.moment = moment
Vue.prototype.moment = moment

// numeral
import numeral from 'numeral'
window.numeral = numeral
Vue.prototype.numeral = numeral

import vuescroll from 'vuescroll'
Vue.use(vuescroll, {
  ops: {
    // The global config
    bar: {
      showDelay: 500,
      // onlyShowBarOnScroll: true,
      keepShow: true,
      background: '#c1c1c1',
      opacity: 1,
      hoverStyle: false,
      specifyBorderRadius: false,
      minSize: 0,
      size: '6px',
      disable: false
    },
    rail: {
      size: '12px'
    },
    scrollButton: {
      enable: true,
      background: 'rgba(255, 255, 255, 0)',
      opacity: 1,
      step: 180,
      mousedownStep: 30
    }
  },
  name: 'vueScroll' // customize component name, default -> vueScroll
})
