import settings from '@/settings'

/**
 * 學校使用者權限管理
 */
class Roles {
  /**
   * 列表
   */
  list() {
    return axios.get(settings.api.fullPath + `/roles`)
  }

  /**
   * 新增
   */
  create(params) {
    return axios.post(settings.api.fullPath + `/roles`, params)
  }

  /**
   * 修改
   */
  update(id, params) {
    return axios.patch(settings.api.fullPath + `/roles/${id}`, params)
  }

  /**
   * 刪除
   */
  delete(id) {
    return axios.delete(settings.api.fullPath + `/roles/${id}`)
  }
}

var roles = new Roles()
export default roles
