import settings from '@/settings'

/**
 * Config 資訊
 */
class Config {
  /**
   * 電費計價類型
   */
  plan() {
    return axios.get(settings.api.fullPath + `/ems/profile/charge/plan`)
  }

  /**
   * api version info
   */
  apiVersion() {
    return axios.get(settings.api.fullPath + `/version`)
  }
}

var config = new Config()
export default config
