module.exports = {
  title:
    process.env.VUE_APP_CITY === 'tainan'
      ? '校園能源管理系統'
      : '中小學冷氣監控系統',
  publicPath: process.env.VUE_APP_PUBLICPATH,
  version: require('../package.json').version,
  versionTime: process.env.VUE_APP_VERSION_TIME,
  api: {
    fullPath: process.env.VUE_APP_API_URL,
    issueReportPath: process.env.VUE_APP_IOT_ISSUE_REPORT_PATH
  },
  LINE: {
    client_id: process.env.VUE_APP_LINE_CLIENT_ID
  },
  edu: {
    path: process.env.VUE_APP_EDU_PATH,
    client_id: process.env.VUE_APP_EDU_CLIENT_ID,
    city: process.env.VUE_APP_CITY
  }
}
