const state = {
  actionOption: [
    {
      value: 1,
      text: '製卡'
    },
    {
      value: 2,
      text: '加值'
    },
    {
      value: 3,
      text: '退款'
    }
  ]
}

const mutations = {}

const actions = {}

const getters = {
  actionOption: (state) => state.actionOption
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
