import store from '@/store'
export default {
  inserted(el, binding) {
    const { value } = binding
    if (value && value instanceof Array && value.length > 0) {
      const permissionRoles = value
      const user = store.getters['user/userData']
      const hasPermission =
        user && user.role ? permissionRoles.includes(user.role.role) : false
      if (!hasPermission) {
        el.innerHTML = ''
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else if (value) {
      throw new Error(`need roles! Like v-permission="[1,2]"`)
    }
  }
}
