import { client, xml } from '@xmpp/client'
import debug from '@xmpp/debug'
import convert from 'xml-js'
import MessageAdapter from './messageAdapter'

/**
 * XMPP
 */
class XMPP {
  /**
   * 登入連線 XMPP
   * 確認.env是否設定下列內容
   * VUE_APP_XMPP_SERVICE_URI = 'wss://emsedutest.fetnet.net:7443/ws/'
   * VUE_APP_XMPP_DOMAIN = 'emsedutest.fetnet.net'
   * VUE_APP_XMPP_RESOURCE = 'EMS'
   */
  start(username, password) {
    if (!username || !password) {
      return
    }

    const xmpp = client({
      service: process.env.VUE_APP_XMPP_SERVICE_URI,
      domain: process.env.VUE_APP_XMPP_DOMAIN,
      resource: process.env.VUE_APP_XMPP_RESOURCE + `${Date.now()}`,
      username: username,
      password: password
    })

    const openDebug = process.env.NODE_ENV !== 'production'
    debug(xmpp, openDebug)

    // 設定 xmpp 連線失敗後重新連線延遲1分鐘
    xmpp.reconnect.delay = 60 * 1000

    xmpp.on('status', (status) => {
      console.debug('status:', status)
    })

    xmpp.on('error', (err) => {
      console.log(`[xmpp] err: ${err}`)
    })

    xmpp.on('offline', () => {
      console.log(`[xmpp] offline xmpp.status: ${xmpp.status}`)
    })

    xmpp.on('stanza', async (stanza) => {
      if (stanza.is('message')) {
        var result = convert.xml2js(stanza, { compact: true })
        if (!result.message.body || !result.message.body._text) {
          return
        }

        console.log(`XMPP 收到訊息: ${result.message.body._text}`)
        try {
          const { typeId, actionId, message, timestemp } = JSON.parse(
            result.message.body._text
          )
          new MessageAdapter(typeId, actionId, message, timestemp)
        } catch (e) {
          console.log(e)
        }

        // this.sendMessage(`收到訊息: ${result.message.body._text}`)
      }
    })
    xmpp.on('online', async (address) => {
      console.log(`[xmpp] online! address:`, address)
      xmpp.send(xml('presence')).catch(console.error)
    })

    xmpp.start().catch(console.error)

    this.xmpp = xmpp
  }

  stop() {
    if (!this.xmpp) {
      return
    }
    console.log('[xmpp] stop')
    this.xmpp.stop().catch(console.error)
  }

  async sendMessage(message, username = 'admin@emsedutest.fetnet.net') {
    if (!this.xmpp) {
      return
    }
    console.log(`[xmpp] sendMessage: ${message}`)
    const messageXml = xml(
      'message',
      { type: 'chat', to: username },
      xml('body', {}, message)
    )
    await this.xmpp.send(messageXml)
  }
}

export default XMPP
