const state = {
  asyncFunction: []
}

const mutations = {
  PUSH_FUNCTION: (state, fun) => {
    state.asyncFunction.push(fun)
  },
  SHIFT_FUNCTION: (state) => {
    state.asyncFunction.shift()
  }
}

const actions = {
  pushFunction({ commit, dispatch }, fun) {
    commit('PUSH_FUNCTION', fun)
  },
  runProcessed({ commit, state }) {
    if (!state.asyncFunction[0]) {
      return
    }
    state.asyncFunction[0]().finally(() => {
      commit('SHIFT_FUNCTION')
    })
  }
}

const getters = {
  length: (state) => state.asyncFunction.length,
  asyncFunction: (state) => state.asyncFunction
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
