<template>
  <div class="overflow-hidden">
    <v-app :class="[bgCSS]">
      <router-view />
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :content-class="snackbar.contentClass"
        :timeout="snackbar.time"
        :bottom="snackbar.position === 'bottom'"
        :top="snackbar.position === 'top'"
        >{{ snackbar.message }}
        <template v-if="snackbar.closable" v-slot:action="{ attrs }">
          <v-btn color="white" icon v-bind="attrs" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
    <v-overlay z-index="600" :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {},
  data: () => ({}),
  computed: {
    ...mapGetters({
      isAPPView: 'page/isAPPView',
      loading: 'page/overlayLoading',
      processed: 'processed/length',
      snackbar: 'snackbar/snackbar'
    }),
    bgCSS() {
      return this.$route.meta.bgCSS ? this.$route.meta.bgCSS : 'BG-01'
    }
  },
  watch: {
    processed() {
      this.runProcessed()
    },
    $route() {
      this.getIP()
    }
  },
  mounted() {
    this.checkAPPView()
    this.getIP()
  },
  methods: {
    ...mapActions({
      checkAPPView: 'page/checkAPPView',
      runProcessed: 'processed/runProcessed',
      getIP: 'user/getIP',
      close: 'snackbar/close'
    })
  }
}
</script>
<style lang="scss">
@import 'assets/style/style';
</style>
