import store from '@/store'
export default {
  inserted(el, binding) {
    const { value } = binding
    if (value) {
      const authoritie = value
      const authorities = store.getters['user/authorities']
      const hasPermission = authorities.includes(authoritie)
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(
        `need roles! Like v-permission-authoritie="'GET_CLASS_METER'"`
      )
    }
  }
}
