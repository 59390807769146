import permission from './permission'
import permissionAuthoritie from './permissionAuthoritie'
import permissionWithCity from './permissionWithCity'
import permissionWithoutCity from './permissionWithoutCity'

const install = function (Vue) {
  Vue.directive('permission', permission)
  Vue.directive('permission-authoritie', permissionAuthoritie)
  Vue.directive('permission-with-city', permissionWithCity)
  Vue.directive('permission-without-city', permissionWithoutCity)
}
if (window.Vue) {
  window['permission'] = permission
  Vue.use(install) // eslint-disable-line
}

permission.install = install
export default permission
