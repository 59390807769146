import settings from '@/settings'

/**
 * 氣象資訊
 */
class Weathers {
  /**
   * 查詢縣市氣象資訊
   */
  city(cityId) {
    return axios.get(settings.api.fullPath + `/weathers/city/${cityId}`, {})
  }

  /**
   * 查詢區域氣象資訊
   */
  district(districtId) {
    return axios.get(
      settings.api.fullPath + `/weathers/district/${districtId}`,
      {}
    )
  }

  /**
   * 查詢學校氣象資訊
   */
  school(schoolId) {
    return axios.get(settings.api.fullPath + `/weathers/school/${schoolId}`, {})
  }
}

var tag = new Weathers()
export default tag
