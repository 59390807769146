import Group from '@/api/ems/Group'
import Device from '@/api/ems/Device'

const state = {
  loadingGroups: false,
  loadingClassrooms: false,
  groups: [],
  classrooms: [],
  updatingClassroomIds: [],
  updatingAcs: []
}

const mutations = {
  SET_GROUPS: (state, groups) => {
    state.groups = groups
  },
  SET_CLASSROOMS: (state, classrooms) => {
    state.classrooms = classrooms
  },
  SET_LOADINGGROUPS: (state, isLoading) => {
    state.loadingGroups = isLoading
  },
  SET_LOADINCLASSROOMS: (state, isLoading) => {
    state.loadingClassrooms = isLoading
  },
  SET_UPDATING_CLASSROOM: (state, classrooms) => {
    state.updatingClassroomIds = state.updatingClassroomIds.concat(classrooms)
  },
  FINISH_CLASSROOM_UPDATING: (state, classroomId) => {
    state.updatingClassroomIds = state.updatingClassroomIds.filter(
      (cls) => cls.classroomId !== classroomId
    )
  },
  CLEAR_CLASSROOM_UPDATING: (state) => {
    state.updatingClassroomIds = []
  },
  SET_UPDATING_AC: (state, acs) => {
    state.updatingAcs = state.updatingAcs.concat(acs)
  },
  FINISH_AC_UPDATING: (state, acId) => {
    state.updatingAcs = state.updatingAcs.filter((acs) => acs.id !== acId)
  }
}

const actions = {
  async getGroups({ commit }, schoolId) {
    try {
      commit('SET_LOADINGGROUPS', true)
      const { data } = await Group.getGroupsClassrooms(schoolId)
      commit('SET_GROUPS', data)
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADINGGROUPS', false)
    }
  },
  async getClassrooms({ commit }, schoolId) {
    try {
      commit('SET_LOADINCLASSROOMS', true)
      const { data } = await Device.getClassroomsDevices(schoolId)
      commit('SET_CLASSROOMS', data)
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADINCLASSROOMS', false)
    }
  },
  updateClassroom({ commit, state }, classroom) {
    let stateClassooms = state.classrooms
    let existIndex = stateClassooms.findIndex(({ id }) => id === classroom.id)
    if (stateClassooms.findIndex !== -1) {
      stateClassooms[existIndex] = classroom
      let newList = [].concat(stateClassooms)
      commit('SET_CLASSROOMS', newList)
    }
  },
  async getClassroomInfo({ commit }, classroomId) {
    try {
      commit('SET_LOADINCLASSROOMS', true)
      const { data } = await Device.getClassroomDevices(classroomId)
      commit('SET_CLASSROOMS', [data])
    } catch (error) {
      console.error(error)
    } finally {
      commit('SET_LOADINCLASSROOMS', false)
    }
  },
  setUpdatingClassroomIds({ commit }, payload) {
    commit(
      'SET_UPDATING_CLASSROOM',
      payload.classrooms.map(({ id }) => ({
        classroomId: id,
        mode: payload.mode
      }))
    )
  },
  finishClassroomUpdating({ commit }, classroomId) {
    commit('FINISH_CLASSROOM_UPDATING', classroomId)
  },
  clearClassroomUpdating({ commit }) {
    commit('CLEAR_CLASSROOM_UPDATING')
  },
  setUpdatingAcs({ commit }, payload) {
    console.log(payload)
    commit(
      'SET_UPDATING_AC',
      payload.acs.map(({ id }) => ({
        id: id,
        type: payload.type
      }))
    )
  },
  finishAcUpdating({ commit }, acId) {
    commit('FINISH_AC_UPDATING', acId)
  }
}
const getters = {
  loadingGroups: (state) => state.loadingGroups,
  loadingClassrooms: (state) => state.loadingClassrooms,
  groups: (state) => state.groups,
  classrooms: (state) => state.classrooms,
  updatingClassroomIds: (state) => state.updatingClassroomIds,
  updatingAcs: (state) => state.updatingAcs
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
