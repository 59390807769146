import settings from '@/settings'

/**
 * 冷氣資訊
 */
class Group {
  /**
   * 新增群組
   */
  create(data) {
    return axios.post(settings.api.fullPath + `/group`, data)
  }

  /**
   * 刪除群組
   */
  delete(deviceId) {
    return axios.delete(settings.api.fullPath + `/group/${deviceId}`)
  }

  /**
   * 修改群組
   */
  patch(groupId, data) {
    return axios.patch(settings.api.fullPath + `/group/${groupId}`, data)
  }

  /**
   * 查詢群組基本資料
   */
  getGroups(schoolId) {
    return axios.get(settings.api.fullPath + `/group/${schoolId}`)
  }
  /**
   * 查詢群組詳細資料
   */
  getDetail(id) {
    return axios.get(settings.api.fullPath + `/group/full/${id}`)
  }
  /**
   * 查詢學校已分配群組之冷氣
   */
  getUsedDevice(schoolId) {
    return axios.get(settings.api.fullPath + `/group/member/${schoolId}`)
  }

  getGroupsClassrooms(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/group/school/${schoolId}/classroom`
    )
  }

  /**
   * 派送群組資料給IPC
   */
  sendDataToIPC(schoolId) {
    return axios.post(
      `${settings.api.fullPath}/group/school/${schoolId}/date-to-ipc`
    )
  }
}

var group = new Group()
export default group
