import Swal from 'sweetalert2'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

/**
 * Message Adapter
 * 處理 XMPP 收到訊息的後續處理
 */
class MessageAdapter {
  /**
   * 設定超過時間(分鐘)不顯示訊息
   */
  get overTime() {
    return 2
  }

  constructor(typeId, actionId, message, timestemp) {
    this.typeId = typeId
    this.actionId = actionId
    this.message = message
    this.timestemp = timestemp

    if (
      !moment(this.timestemp).isBetween(
        moment().subtract(this.overTime, 'minutes'),
        moment()
      )
    ) {
      return
    }

    // 資料是否重整
    if (
      (this.typeId === 1 && [5, 6].includes(this.actionId)) ||
      this.typeId === 2 ||
      this.typeId === 3 ||
      (this.typeId === 4 && [4, 5, 6].includes(this.actionId)) ||
      (this.typeId === 6 && [1, 2].includes(this.actionId))
    ) {
      this.showMassage(true)
      return
    }

    this.showMassage()
  }

  showMassage(pageReload = false) {
    const typeOptions = store.getters['alert/typeOptions']

    let showType = null
    typeOptions.forEach((element) => {
      if (element.value !== this.typeId) {
        return
      }
      showType = element
    })

    if (!showType) {
      console.log('alert type not find')
      return
    }

    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href =
      'https://cdn.jsdelivr.net/npm/font-awesome@4.7.0/css/font-awesome.min.css'
    document.head.appendChild(link)
    const style = document.createElement('style')
    style.innerHTML = `
      .btn {
        padding: 8px 12px;
        margin: 5px;
        font-weight: bold;
        border: 2px solid #0072f7;
        transition: all 0.3s ease;
      }
      
      .btn-cancel {
        color: rgb(0, 114, 247);
        padding: 8px 16px;
      }
      
      .btn-cancel:hover {
        background: rgb(0, 114, 247, 0.05);
      }
      
      .btn-confirm {
        background: #0072f7;
        color: #fff;
      }
      
      .btn-confirm:hover {
        background: rgb(0, 114, 247, 0.9);
      }
    `
    document.head.appendChild(style)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'v-btn btn btn-confirm',
        cancelButton: 'v-btn btn btn-cancel'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: `${showType.text}通知`,
        text: this.message,
        confirmButtonText: '前往查看 <i class="fa fa-arrow-right"></i>',
        confirmButtonColor: 'var(--v-primary-base)',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: '確認',
        reverseButtons: true
      })
      .then((result) => {
        let schoolId = JSON.parse(localStorage.getItem('user')).school_id
        if (result.isConfirmed && schoolId) {
          router.push({
            path: `/school/${schoolId}/device/damaged#search`
          })
        } else if (pageReload) {
          router.history.go()
        }
      })
  }
}

export default MessageAdapter
