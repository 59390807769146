import settings from '@/settings'

/**
 * 學校基本資訊
 */
class Building {
  /**
   * 查詢完整大樓(含底下所有層級和設備)
   */
  getAll(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/building/info/${schoolId}`)
  }

  /**
   * 編輯大樓顯示名稱
   */
  updateBuildingName(buildingId, newName) {
    return axios.put(
      settings.api.fullPath + `/ems/building/${buildingId}`,
      newName
    )
  }
}

var building = new Building()
export default building
