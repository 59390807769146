import Weathers from '@/api/ems/Weathers'
import SchoolDashboard from '@/api/ems/schoolDashboard'

const state = {
  schoolId: null,
  weather: null,
  PV: null,
  solar: {},
  energy: {},
  acEnergy: {},
  CO2: {},
  realtimePV: null,
  whetherSolarMeter: false,
  serverTime: null
}

const mutations = {
  SET_SCHOOL_ID: (state, schoolId) => {
    state.schoolId = schoolId
  },
  SET_WEATHER: (state, weather) => {
    state.weather = weather
  },
  SET_PV: (state, PV) => {
    state.PV = PV
  },
  SET_SOLAR: (state, solar) => {
    state.solar = solar
  },
  SET_ENERGY: (state, energy) => {
    state.energy = energy
  },
  SET_AC_ENERGY: (state, acEnergy) => {
    state.acEnergy = acEnergy
  },
  SET_CO2: (state, CO2) => {
    state.CO2 = CO2
  },
  SET_REALTIME_PV: (state, realtimePV) => {
    state.realtimePV = realtimePV
  },
  SET_WHETHER_SOLAR_METER: (state, status) => {
    state.whetherSolarMeter = status
  },
  SET_SERVER_TIME: (state, serverTime) => {
    state.serverTime = serverTime
  }
}

const actions = {
  setSchoolId({ commit }, schoolId) {
    commit('SET_SCHOOL_ID', schoolId)
  },
  /**
   * 查詢系統時間
   */
  getServerTime({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getServerTime().then((response) => {
        if (!response.data) {
          return
        }
        commit('SET_SERVER_TIME', response.data)
        resolve(response.data)
      })
    })
  },
  getWeather({ commit, state }) {
    return new Promise((resolve, reject) => {
      Weathers.school(state.schoolId)
        .then((response) => {
          if (!response.data) {
            return
          }
          commit('SET_WEATHER', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  /**
   * 查詢學校太陽能發電資料
   */
  getPV({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getPV(state.schoolId)
        .then((response) => {
          if (!response.data) {
            return
          }
          commit('SET_PV', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  /**
   * 查詢學校太陽能即時資料
   */
  getSolar({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getSolar(state.schoolId)
        .then((response) => {
          if (!response.data) {
            return
          }
          commit('SET_SOLAR', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  /**
   * 查詢學校用電資訊
   */
  getEnergy({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getEnergy(state.schoolId)
        .then((response) => {
          if (!response.data) {
            return
          }
          commit('SET_ENERGY', response.data)
          resolve(response.data)
        })
        .catch((error) => {
          reject(error.response)
        })
    })
  },
  /**
   * 查詢學校冷氣用電資訊
   */
  getACEnergy({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getACEnergy(state.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        commit('SET_AC_ENERGY', response.data)
        resolve(response.data)
      })
    })
  },
  /**
   * 查詢學校減碳量
   */
  getCO2({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getCO2(state.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        commit('SET_CO2', response.data)
        resolve(response.data)
      })
    })
  },

  /**
   * 查詢學校即時用電資訊
   */
  getRealtimePV({ commit, state }) {
    return new Promise((resolve, reject) => {
      SchoolDashboard.getRealtimePV(state.schoolId).then((response) => {
        if (!response.data) {
          return
        }
        commit('SET_REALTIME_PV', response.data)
        resolve(response.data)
      })
    })
  },

  setWhetherSolarMeter({ commit }, status) {
    commit('SET_WHETHER_SOLAR_METER', status)
  }
}
const getters = {
  weather: (state) => {
    if (state.weather) {
      Object.keys(state.weather).map((key) => {
        if (state.weather[key] === '-99' || state.weather[key] === '') {
          state.weather[key] = null
        }
      })
    }
    return state.weather
  },
  PV: (state) => state.PV,
  solar: (state) => state.solar,
  energy: (state) => state.energy,
  acEnergy: (state) => state.acEnergy,
  CO2: (state) => state.CO2,
  realtimePV: (state) => state.realtimePV,
  whetherSolarMeter: (state) => state.whetherSolarMeter,
  serverTime: (state) => state.serverTime
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
