import router from '../router'
import store from '../store'
import settings from '@/settings'

// axios
import axios from 'axios'
import user from '../api/user'
window.axios = axios
axios.defaults.timeout = 600000

const refreshTokenPath = `${settings.api.fullPath}/oauth/token`

const logoutfn = async () => {
  try {
    await store.dispatch('user/logout')
  } catch (error) {
    // console.error(error)
    localStorage.clear()
  } finally {
    router.push('/login').catch(() => {})
  }
}

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.config.url === refreshTokenPath) return error.response
    if (!error.response) {
      return Promise.reject({
        response: {
          data: null
        }
      })
    }
    switch (error.response.status) {
      case 500:
        throw error
        break
      case 401:
        if (error.config.url !== refreshTokenPath) {
          // console.error('access token is expired')
          const originalRequest = error.config
          try {
            await store.dispatch('user/refreshToken')
            originalRequest.headers.Authorization =
              'Bearer ' + JSON.parse(localStorage.getItem('user')).access_token
            return axios(originalRequest)
          } catch (error) {
            console.error(error)
          }
        } else {
          // console.error('refresh token is expired')
          logoutfn()
        }
      case 403:
        console.error(error.config)
        console.error('token error')
        logoutfn()
        throw error
        break
    }
    return Promise.reject(error)
  }
)

export default axios
