import settings from '@/settings'
import Qs from 'qs'

/**
 * 電表資料
 */
class Meter {
  /**
   * 透過學校ID查詢學校電表
   */
  getSchoolMeters(schoolId) {
    return axios.get(settings.api.fullPath + `/ems/meter/school/${schoolId}`)
  }
  /**
   * 取得學校所有班級的冷氣和電表即時資訊
   */
  getAllClassMeters(schoolId) {
    return axios.get(
      settings.api.fullPath + `/ems/meter/school/${schoolId}/full2`
    )
  }
  /**
   * 取得班級所有電表
   */
  getClassMeters(classId) {
    return axios.get(settings.api.fullPath + `/ems/meter/class/${classId}`)
  }
  /**
   * 查詢PV電表完整資料
   */
  getPVMeter(meterId) {
    return axios.get(settings.api.fullPath + `/ems/meter/${meterId}/pv`)
  }
  /**
   * 教室電表的讀卡機狀態
   */
  getMeterRelay(meterId) {
    return axios.get(settings.api.fullPath + `/ems/meter/${meterId}/relay`)
  }
  /**
   * 查詢電表詳細資料
   */
  // getMeter(meterId) {
  //   return axios.get(settings.api.fullPath + `/ems/meter/${meterId}`)
  // }
  /**
   * 查詢電表完整資料
   */
  getMeterDetail(meterId) {
    return axios.get(settings.api.fullPath + `/ems/meter/${meterId}/full`)
  }
  /**
   * 查詢電表即時資料
   */
  getMeterRealtime(meterId) {
    return axios.get(settings.api.fullPath + `/ems/meter/${meterId}/realtime`)
  }
  /**
   * 查詢電表冷氣資料
   */
  getMeterAC(meterId) {
    return axios.get(settings.api.fullPath + `/ems/meter/${meterId}/ac`)
  }

  // 取得 (type: class/school) 報表資訊
  statistic(meterId, reportName, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/statistic/meter/${meterId}/${reportName}`,
      {
        params: params
      }
    )
  }

  // 取得學校報表下載連結
  schoolStatisticExport(meterId, reportName, fileType, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/report/statistic/meter/${meterId}/${reportName}.${fileType}`,
      {
        params: params
      }
    )
  }

  // 取得學校報表下載連結
  classStatisticExport(params) {
    return axios.get(settings.api.fullPath + `/ems/meter/report/class`, {
      params: params,
      responseType: 'blob'
    })
  }

  // 上傳統計報表
  statisticUpload(type, params) {
    return axios.get(
      settings.api.fullPath + `/ems/meter/report/${type}/upload`,
      {
        params: params
      }
    )
  }

  // 查詢電表歷史資訊
  meterHistory(params) {
    return axios.get(settings.api.fullPath + `/ems/meter/history`, {
      params: params
    })
  }

  // 查詢群組電表歷史資訊
  meterGroupHistory(params) {
    return axios.get(settings.api.fullPath + `/ems/meter/history/group`, {
      params: params
    })
  }

  // 查詢縣市用電狀況
  city(cityId) {
    return axios.get(settings.api.fullPath + `/ems/meter/city/${cityId}`)
  }
  // 查詢行政區用電狀況
  district(districtId) {
    return axios.get(
      settings.api.fullPath + `/ems/meter/district/${districtId}`
    )
  }

  /**
   * 取得縣市/行政區統計報表資訊
   */
  cityReport(params) {
    return axios.get(settings.api.fullPath + `/ems/meter/report/all`, {
      params: params,
      paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }

  // 學校所有教室日報表
  exportDailyClassroomReport(schoolId, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/download/report/school/${schoolId}/daily/classroom`,
      {
        params: params
      }
    )
  }

  // 學校所有教室月報表
  exportMonthlyClassroomReport(schoolId, params) {
    return axios.get(
      settings.api.fullPath +
        `/ems/download/report/school/${schoolId}/monthly/classroom`,
      {
        params: params
      }
    )
  }
}

var meter = new Meter()
export default meter
