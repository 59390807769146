// Nagiosxi Controller
const state = {
  meterType: [
    {
      id: 'IPC',
      name: 'IPC'
    },
    {
      id: 'AIRCONDITIONER',
      name: '冷氣控制器'
    },
    {
      id: 'CARDREADER',
      name: '讀卡機'
    },
    {
      id: 'SOLARMETER',
      name: '再生能源'
    },
    {
      id: 'CLASSMETER',
      name: '教室電表'
    },
    {
      id: 'ACMETER',
      name: '冷氣總表'
    },
    // {
    //   id: 'DEPOSITSYSTEM',
    //   name: '中央儲值系統'
    // },
    {
      id: 'SCHOOLMETER',
      name: '學校總表'
    },
    {
      id: 'IR_AIRCONDITIONER',
      name: '紅外線控制器'
    }
  ],
  abnormaType: [
    {
      id: 'disconnected',
      name: '設備斷線'
    }
  ]
}

const mutations = {}

const actions = {}
const getters = {
  meterType: (state) => state.meterType,
  abnormaType: (state) => state.abnormaType
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
