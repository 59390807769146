<template>
  <div class="dashboard">
    <!-- dashboard -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: {
    // HelloWorld
  },
  data() {
    return {
      hasLoginTainan: false
    }
  },
  computed: {
    ...mapGetters({
      userPermission: 'user/userPermission',
      userRole: 'user/userRole',
      tags: 'page/tags'
    })
  },
  watch: {
    tags(val) {
      if (val) this.judgeRediect()
    }
  },
  mounted() {
    this.judgeRediect()
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      logout: 'user/logout'
    }),
    judgeRediect() {
      // 系統管理員 登入後進入系統管理後台
      if (this.userRole?.role === 'SYS_ADMIN') {
        this.$router.push({ path: '/admin' })
        return
      }
      // 未登入
      if (!this.userPermission || !this.userRole) {
        // 台南登入
        if (!this.hasLoginTainan) {
          this.hasLoginTainan = true
          let state = this.$route.query.state
          let code = this.$route.query.code
          let msg = this.$route.query.msg
          console.log(state, code, msg)
          if (state) {
            if (code) {
              this.loginTainan(state, code)
            }
          }
          if (msg) {
            this.loginFailed(`error message: ${msg}`)
          }
        }

        if (this.tags) {
          this.$router.push({ path: this.tags.to })
          return
        }
      }
      if (
        this.userRole.role?.indexOf('CLASS_USER') > -1 &&
        this.userPermission?.classId
      ) {
        //  || !userPermission.schoolId && userPermission.classId
        this.$router.push({ path: `/class/${this.userPermission.classId}` })
      } else if (this.userPermission?.schoolId) {
        this.$router.push({ path: `/school/${this.userPermission.schoolId}` })
      } else if (this.tags) {
        this.$router.push({ path: this.tags.to })
      }
    },
    async loginTainan(state, code) {
      let currentTime = new Date()
      let stateList = JSON.parse(localStorage.getItem('stateList'))
      let existState = stateList.find((item) => item === state)
      if (
        existState &&
        moment(new Date(atob(existState))).add(10, 'm') > currentTime &&
        code
      ) {
        try {
          const params = {
            username: '__TN_OPENID__',
            password: code
          }
          await this.login(params)
          this.$router.push({ name: 'Home' })
        } catch (error) {
          console.error(error)
          let errorMessage = ''
          if (error) errorMessage = JSON.stringify(error)
          this.loginFailed(errorMessage)
        } finally {
          stateList = stateList.filter((item) => item !== existState)
          localStorage.setItem('stateList', JSON.stringify(stateList))
        }
        return
      } else {
        let errorMessage = ''
        if (!existState) errorMessage += 'state 不存在;'
        if (
          existState &&
          moment(new Date(atob(existState))).add(10, 'm') < currentTime
        )
          errorMessage += 'state 已過期;'
        if (!code) errorMessage += 'code 不存在'
        this.loginFailed(errorMessage)
      }
    },

    loginFailed(errorMessage) {
      this.logout()
      alert(`登入失敗，請重試${errorMessage ? '\n' + errorMessage : ''}`)
    }
  }
}
</script>
