import settings from '@/settings'

/**
 * 標籤
 */
class Tag {
  /**
   * 列表
   */
  search(url) {
    return axios.get(settings.api.fullPath + `/ems/tag${url}`)
  }
}

var tag = new Tag()
export default tag
