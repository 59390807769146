import Config from '@/api/config'

const state = {
  branchPlan: [],
  chartColors: ['#00ddcb', '#f6b400', '#0067f6', '#d000f6'],
  schoolType: [
    {
      key: 'elementary',
      name: '國小'
    },
    {
      key: 'junior',
      name: '國中'
    },
    {
      key: 'senior',
      name: '高中'
    },
    {
      key: 'vocational',
      name: '高職'
    },
    {
      key: 'sixyear',
      name: '完全中學'
    },
    {
      key: 'nineyear',
      name: '國中國小'
    }
  ]
}

const mutations = {
  SET_BRANCHPLAN: (state, plan) => {
    state.branchPlan = plan
  }
}

const actions = {
  async getBranchPlans({ commit }) {
    try {
      const { data } = await Config.plan()
      commit('SET_BRANCHPLAN', data)
    } catch (error) {
      console.error(error)
    }
  }
}

const getters = {
  branchPlan: (state) => state.branchPlan,
  chartColors: (state) => state.chartColors,
  schoolType: (state) => state.schoolType
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
