import settings from '@/settings'

/**
 * 與 IPC 介接相關資訊
 */
class _IPC {
  /**
   * 透過CAT送出學校設備的資料給IPC
   */
  syncIPC(schoolSerialNumber) {
    return axios.get(
      settings.api.fullPath + `/ems/ipc/rpc/school/${schoolSerialNumber}/result`
    )
  }

  /**
   * 讀取該縣市所有的IPC 連線狀態
   */
  getCityIPC(cityId) {
    return axios.get(
      `${settings.api.fullPath}/ems/ipc/connection/city/${cityId}`
    )
  }

  /**
   * 讀取學校 IPC 連線狀態
   */
  getSchoolIPC(schoolId) {
    return axios.get(
      `${settings.api.fullPath}/ems/ipc/connection/school/${schoolId}`
    )
  }
}

var ipc = new _IPC()
export default ipc
